import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { getUrlColor } from '../utils';

import UnitAvailability from '../pages/UnitAvailability';
import NotFound from '../pages/NotFound';

/** Vendor CSS **/
import '../css/et-icons.css';

const bgMatte = getUrlColor('bg_matte') || 'none';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700|Raleway:400,500,600,700');
  * {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    margin: 0;
    overflow: auto;
    padding: 0;
    width: 100%;
    touch-action: manipulation;
    -webkit-overflow-scrolling: touch;
  }

  body {
    font-family: 'Muli', sans-serif;
    background-color: ${bgMatte};
  }
`;

const App = ({ propertyDetails }) => (
  <>
    <GlobalStyles />
    <Router>
      <Switch>
        <Route
          path='/unit-availability/:organizationId/:propertyId'
          component={(routeProps) => (
            <UnitAvailability
              propertyDetails={propertyDetails}
              {...routeProps}
            />
          )}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  </>
);

export default App;
