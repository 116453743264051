import { getUrlParam, getUrlColor } from '../utils';

/**
 * Certain elements of the application can be customized through the URL:
 * /unit-availability/{organizationId}/{propertyId}?bg_background={color}&ft_header={color}&sz_font={size}
 */

/**
 * Customizable Background Colors
 */
const bgBackground = getUrlColor('bg_background') || '#FFFFFF'; // global background-color
const bgUnitTop = getUrlColor('bg_unit_top') || '#FFFFFF'; // background top portion of AvailableUnit
const bgUnitBottom = getUrlColor('bg_unit_bottom') || '#FAF9F9'; // background bottom portion of Available Unit
const bgInput = getUrlColor('bg_input') || '#FFFFFF'; // background for form inputs
const bgButtonPrimary = getUrlColor('bg_button_primary') || '#666666'; // background for primary button (ex: Search, Load More)
const bgButtonSecondary = getUrlColor('bg_button_secondary') || '#e2e2e2'; // background for secondary button (ex: Apply Now)
const bgModal = getUrlColor('bg_modal') || '#FFFFFF'; // background for FloorPlan Modal
const bgBorder = getUrlColor('bg_border') || '#DADADA'; // global border-color

/**
 * Customizable Font Colors
 */
const ftBody = getUrlColor('ft_body') || '#2A2A2A'; // global color for text
const ftHeader = getUrlColor('ft_header') || '#666666'; // color for H1 Header & Loading Spinner
const ftLabel = getUrlColor('ft_label') || '#666666'; // color for form labels
const ftDetail = getUrlColor('ft_detail') || '#808080'; // color for details (ex: Beds, Baths)
const ftFooter = getUrlColor('ft_footer') || '#4A4A4A'; // color for footer
const ftFooterLink = getUrlColor('ft_footer_link') || '#383A3C'; // color for footer

/**
 * Customizable Sizes
 */
const szFont = getUrlParam('sz_font') || '14px'; // global font-size
const szStandard = getUrlParam('sz_standard') || '15px'; // used for spacing between elements
const szPadding = getUrlParam('sz_padding') || '20px'; // used for padding around content

const colors = {
  background: bgBackground,
  modal: bgModal,
  border: bgBorder,

  unitTop: bgUnitTop,
  unitBottom: bgUnitBottom,

  opacitySteps: [1.0, 0.8, 0.6, 0.2] /* [Up, Hover, Active, Disabled] */,

  formInput: bgInput,
  buttonPrimary: bgButtonPrimary,
  buttonPrimaryFont: '#FFFFFF',
  buttonSecondary: bgButtonSecondary,
  buttonSecondaryFont: ftBody,

  header: ftHeader,
  label: ftLabel,
  body: ftBody,
  detail: ftDetail,
  spinner: ftHeader,
  footer: ftFooter,
  footerLink: ftFooterLink,
};

const sizes = {
  radius: '4px',

  buttonWidth: '150px',

  standard: szStandard,
  padding: szPadding,

  font: szFont,

  formHeight: '40px',
};

const screens = {
  mobile: '(max-width: 767px)',
};

const theme = { colors, sizes, screens };

export default theme;
