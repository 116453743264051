import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  appearance: none;
  /** Totally "borrowed" from Bootstrap :p **/
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: ${(p) => p.theme.colors.formInput}
  border: none;
  border-radius: ${(p) => p.theme.sizes.radius};
  box-shadow: 0 0 0 1px ${(p) => p.theme.colors.border} inset;
  font-size: 16px; /* disables auto-zooming on Safari iOS */
  height: ${(p) => p.theme.sizes.formHeight};
  padding-bottom: 0;
  padding-top: 0;
  padding-left: calc(${(p) => p.theme.sizes.standard} - 2px);
  padding-right: 33px;
  width: 100%;
`;

const Select = (props) => {
  const renderOptions = props.options.reduce((prev, { value, text }, idx) => {
    prev.push(
      <option key={idx} value={value}>
        {text}
      </option>,
    );

    return prev;
  }, []);

  return (
    <div>
      <StyledSelect {...props}>{renderOptions}</StyledSelect>
    </div>
  );
};

export default Select;
