import React from 'react';

const Form = (props) => {
  const handleFormSubmission = (event, callback) => {
    event.preventDefault();
      
    const values = {};
    for(let i = 0; i < event.target.length; i++) {
      const target = event.target[i];
      if (target.name) {
        values[target.name] = target.value;
      }
    };
  
    callback(values);
    return true;
  };

  return (
    <form {...props} onSubmit={(event) => handleFormSubmission(event, props.onSubmit)}>
      {props.children}
    </form>
  );
};

export default Form;