import React from 'react';
import styled from 'styled-components';

const StyledToggleWrapper = styled.div``;

const StyledToggle = styled.button`
  font-size: 16px;
  background: ${(p) =>
    p.isOpen
      ? `${p.theme.colors.unitBottom}`
      : `${p.theme.colors.buttonPrimary}`};
  color: ${(p) => (p.isOpen ? 'unset' : 'white')};
  border: none;
  border-left: 1px solid ${(p) => p.theme.colors.border};
  height: 100%;
  cursor: pointer;
  padding: 0;
  width: 100%;
  transition: background-color 225ms ease-in, color 225ms ease-in;
`;

const Toggle = (props) => {
  const { isOpen, handleToggle } = props;
  return (
    <StyledToggleWrapper {...props}>
      <StyledToggle isOpen={isOpen} onClick={handleToggle}>
        <i className={isOpen ? 'et-chevron-up' : 'et-chevron-down'} />
      </StyledToggle>
    </StyledToggleWrapper>
  );
};

export default Toggle;
