import React from 'react';
import styled from 'styled-components';

const StyledModal = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  
  & * {
    -webkit-transform: translate3d(0, 0, 0);
  }
`;

const StyledShadow = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1100;
`;

const StyledContent = styled.div`
  align-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.modal};
  border-radius: calc(${(p) => p.theme.sizes.radius} * 2);
  bottom: ${(p) => p.theme.sizes.padding};
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  justify-items: center;
  left: ${(p) => p.theme.sizes.padding};
  padding: ${(p) => p.theme.sizes.padding};
  position: absolute;
  right: ${(p) => p.theme.sizes.padding};
  top: ${(p) => p.theme.sizes.padding};
  z-index: 1200;
`;

const StyledClose = styled.div`
  color: ${(p) => p.theme.colors.body};
  cursor: pointer;
  font-size: 30px;
  height: 50px;
  line-height: 50px;
  opacity: ${(p) => p.theme.colors.opacitySteps[0]};
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 50px;
  z-index: 1300;
`;

const StyledImage = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const FloorPlanModal = (props) => {
  if (props.target) {
    const handleModal = props.handleModal;

    return (
      <StyledModal>
        <StyledShadow
          onClick={() => {
            handleModal('');
          }}
        ></StyledShadow>
        <StyledContent {...props}>
          <StyledClose
            onClick={() => {
              handleModal('');
            }}
          >
            &#10005;
          </StyledClose>
          <StyledImage src={props.target} />
        </StyledContent>
      </StyledModal>
    );
  } else {
    return false;
  }
};

export default FloorPlanModal;
