import React from 'react';
import styled from 'styled-components';
import FloorPlan from './FloorPlan';
import GeneralInfo from './GeneralInfo';
import Toggle from './Toggle';
import DetailInfo from './DetailInfo';

const StyledTop = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.colors.unitTop};
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;

const StyledBottom = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: ${(p) => p.theme.colors.unitBottom};
  padding: ${(p) => p.theme.sizes.padding};
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;

const DesktopFloorPlan = styled.div`
  flex: 0 0 220px;
  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    display: none;
  }
`;

const MobileFloorPlan = styled.div`
  display: none;

  @media screen and ${(p) => p.theme.screens.mobile} {
    display: block;
    margin-bottom: ${(p) => p.theme.sizes.standard};
  }
`;

const StyledToggle = styled(Toggle)`
  background-color: ${(p) => p.theme.colors.bgButtonPrimary};
  flex: 0 0 35px;
`;

const AvailableUnit = ({
  applyNowEnabled,
  unit,
  isOpen,
  handleToggle,
  handleModal,
}) => {
  const renderFloorPlan = () => {
    const floorPlanImageName = unit.floorPlanImageName;
    return (
      <FloorPlan
        unitNumber={unit.unitNumber}
        floorPlanImageName={floorPlanImageName}
        handleModal={handleModal}
      />
    );
  };

  return (
    <div>
      <StyledTop>
        <DesktopFloorPlan>{renderFloorPlan()}</DesktopFloorPlan>
        <GeneralInfo {...unit} applyNowEnabled={applyNowEnabled} />
        <StyledToggle isOpen={isOpen} handleToggle={handleToggle} />
      </StyledTop>
      <StyledBottom isOpen={isOpen}>
        <MobileFloorPlan>{renderFloorPlan()}</MobileFloorPlan>
        <DetailInfo {...unit} />
      </StyledBottom>
    </div>
  );
};

export default AvailableUnit;
