import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotateSpinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledWrapper = styled.div`
  display: inline-block;
  padding: ${(p) => p.theme.sizes.standard};
`;

const StyledSpinner = styled.div`
  position: relative;
  width: ${(p) => (p.size ? `${p.size}` : '100%')};
  height: ${(p) => (p.size ? `${p.size}` : '100%')};

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(p) => (p.size ? `${p.size}` : '100%')};
    height: ${(p) => (p.size ? `${p.size}` : '100%')};
    border: 6px solid ${(p) => p.theme.colors.spinner};
    border-radius: 50%;
    animation: ${rotateSpinner} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p) => p.theme.colors.spinner} transparent transparent
      transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Spinner = (props) => {
  return (
    <StyledWrapper>
      <StyledSpinner {...props}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledSpinner>
    </StyledWrapper>
  );
};

export default Spinner;
