import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  color: ${(p) => p.theme.colors.label};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 16px;
`;

const Label = (props) => {
  return <StyledLabel {...props}>{props.children}</StyledLabel>;
};

export default Label;
