import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const requestAPI = (url, data, options) => {
  const requestOptions = {
    headers: {
      Authorization: localStorage.getItem('session_id'),
      'Content-Type': 'application/json',
    },
    data: undefined,
  };
  if (data) {
    requestOptions.data = data;
  }

  return axios
    .request({
      url: `${API_URL}${url}`,
      ...requestOptions,
      ...options,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const get = (url, options) => {
  return requestAPI(url, null, { method: 'get', ...options });
};

export const post = (url, data, options) => {
  return requestAPI(url, data, { method: 'post', ...options });
};

export const put = () => {
  return requestAPI(url, data, { method: 'put', ...options });
};
