import React from 'react';
import styled from 'styled-components';

import H2 from '../../atoms/H2';
import Button from '../../atoms/Button';
import Form from '../../atoms/Form';
import MoveInRangeField from './MoveInRangeField';
import BedroomsField from './BedroomsField';

const StyledForm = styled(Form)`
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  padding: 0px ${(p) => p.theme.sizes.padding} ${(p) => p.theme.sizes.padding};

  /* form:invalid is not supported in IE; however, the behavior remains the same (form won't submit if invalid) */
  &:invalid button[type='submit'] {
    cursor: not-allowed;
    opacity: ${(p) => p.theme.colors.opacitySteps[3]};
  }
`;

const StyledInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 5%;

  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    flex-wrap: wrap;
    margin: 0;
  }
`;

const StyledMoveInRangeField = styled(MoveInRangeField)`
  flex: 1;
  margin: 0 calc(${(p) => p.theme.sizes.standard} / 2);

  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    flex-basis: 100%;
    margin: 0;
  }
`;

const StyledBedroomsField = styled(BedroomsField)`
  flex: 1;
  margin: 0 calc(${(p) => p.theme.sizes.standard} / 2);

  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    flex-basis: 100%;
    margin: 0;
  }
`;

const SearchButton = styled(Button)`
  flex: 0 0 ${(p) => p.theme.sizes.buttonWidth};
  margin: 0 calc(${(p) => p.theme.sizes.standard} / 2);

  @media screen and ${(p) => p.theme.screens.mobile} {
    flex: 1 100%;
    margin: ${(p) => p.theme.sizes.standard} 0 0 0;
  }
`;

const AvailableUnitForm = ({ handleSubmit, floorPlans }) => {
  return (
    <StyledForm onSubmit={handleSubmit}>
      <H2>What are you looking for?</H2>
      <StyledInputs>
        <StyledMoveInRangeField />
        <StyledBedroomsField floorPlans={floorPlans} />
        <SearchButton btnStyle='primary' type='submit'>
          Search
        </SearchButton>
      </StyledInputs>
    </StyledForm>
  );
};

export default AvailableUnitForm;
