import React, { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sort, ascend, prop, uniqBy } from 'ramda';

import Label from '../../atoms/Label';
import Select from '../../atoms/Select';

const DEFAULT_DESIRED_BEDS_OPTIONS = [
  { text: 'Studio', value: 0 },
  { text: '1 Bedroom', value: 1 },
  { text: '2 Bedrooms', value: 2 },
  { text: '3 Bedrooms', value: 3 },
  { text: '4 Bedrooms', value: 4 },
  { text: '5 Bedrooms', value: 5 },
];

const getOptionText = (nBeds) => {
  if (nBeds === 0) {
    return 'Studio';
  }
  if (nBeds === 1) {
    return '1 Bedroom';
  }
  return `${nBeds} Bedrooms`;
};

const BedroomsField = (props) => {
  const flags = useFlags();

  const desiredBedsOptions = useMemo(() => {
    if (flags.availabilityEmbedNoOfBedroomsAtProperty) {
      return sort(
        ascend(prop('value')),
        uniqBy(prop('nBeds'), props.floorPlans).map((floorPlan) => ({
          text: getOptionText(floorPlan.nBeds),
          value: floorPlan.nBeds,
        })),
      );
    }
    return DEFAULT_DESIRED_BEDS_OPTIONS;
  }, [flags.availabilityEmbedNoOfBedroomsAtProperty, props.floorPlans]);

  return (
    <div className={props.className}>
      <Label htmlFor='desiredBeds'>Bedrooms</Label>
      <Select
        id='desiredBeds'
        name='desiredBeds'
        options={desiredBedsOptions}
      />
    </div>
  );
};

export default BedroomsField;
