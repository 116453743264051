/**
 * Available Unit Form
 */
export const UNITFORM_30_DAYS = '30_DAYS';
export const UNITFORM_60_DAYS = '60_DAYS';
export const UNITFORM_90_DAYS = '90_DAYS';
export const UNITFORM_ALL_DAYS = 'ALL_DAYS';

/**
 * Available Unit List
 */
export const UNITLIST_PERFECT_HOME_MAX = 5;
export const UNITLIST_INITIAL_SHOWING = 3;
export const UNITLIST_LOAD_MORE_STEP = 3;
export const UNITLIST_NO_PERFECT_MSG =
  'We do not have your exact match available, but we have selected similar choices just for you.';
export const UNITLIST_NO_RESULTS_MSG = 'There are no homes available.';

/**
 * Available Unit Service
 */
export const UNITSERVICE_RESULT_LIMIT = 15;

export const DATE_FORMAT_DISPLAY = 'MM/DD/YYYY';
export const DATE_FORMAT_DB = 'YYYY-MM-DD';
