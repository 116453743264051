import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { formatNumber } from '../../../utils';
import BedBath from './BedBath';
import ApplyNowButton from '../../molecules/ApplyNowButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DATE_FORMAT_DISPLAY } from '../../../utils/constants';

const StyledGeneralInfo = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: ${(p) => p.theme.sizes.padding};
  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const Primary = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 50%;

  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    flex: 0 0 100%;
    margin-bottom: ${(p) => p.theme.sizes.standard};
  }
`;

const Secondary = styled.div`
  display: flex;
  flex: 0 0 25%;
  flex-direction: column;
  justify-content: space-between;

  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    flex: 0 0 100%;
    flex-direction: row;
  }
`;

const UnitName = styled.p`
  flex: 0 0 100%;
  font-family: 'Raleway', sans-serif;
  color: ${(p) => p.theme.colors.label};
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  margin: 0 0 ${(p) => p.theme.sizes.standard} 0;
`;

const MoveInDate = styled.p`
  font-size: ${(p) => p.theme.sizes.font};
  line-height: 30px;

  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    flex: 0 0 50%;
    margin: 0;
  }
`;

const QuotingRent = styled(MoveInDate)`
  font-weight: bold;
  line-height: 25px;
  margin: 0 0 ${(p) => p.theme.sizes.standard} 0;

  /* Mobile */
  @media screen and ${(p) => p.theme.screens.mobile} {
    line-height: 30px;
    order: 2;
  }
`;

const UnitNumber = styled.p`
  display: none;
  flex: 0 0 100%;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  @media screen and ${(p) => p.theme.screens.mobile} {
    margin-bottom: 8px;
    display: unset;
  }
`;

const GeneralInfo = (props) => {
  const { assigningUnitsMoveInDates } = useFlags();
  const {
    applyNowEnabled,
    floorPlanName,
    floorPlanBeds,
    floorPlanBaths,
    unitQuotingRent,
    unitMoveInDate,
    unitNumber,
    unitMoveInEndDate,
  } = props;

  const moveInDateValue = unitMoveInDate
    ? moment(unitMoveInDate).format(DATE_FORMAT_DISPLAY)
    : 'Contact Us';

  const desiredMoveEndDate =
    moment(unitMoveInEndDate).format(DATE_FORMAT_DISPLAY);
  return (
    <StyledGeneralInfo>
      <Primary>
        <UnitNumber>Apartment #{unitNumber}</UnitNumber>
        <UnitName>{floorPlanName}</UnitName>
        <BedBath txtStyle='beds' count={floorPlanBeds} />
        <BedBath txtStyle='baths' count={floorPlanBaths} />
      </Primary>
      <Secondary>
        <QuotingRent>
          <span>${formatNumber(unitQuotingRent)}</span> / Month
        </QuotingRent>
        <MoveInDate>
          Move In Ready:{' '}
          <span>
            {moveInDateValue}
            {assigningUnitsMoveInDates && (
              <>
                {'-'}
                {desiredMoveEndDate}
              </>
            )}
          </span>
        </MoveInDate>
      </Secondary>
      {applyNowEnabled && <ApplyNowButton urlState={props} />}
    </StyledGeneralInfo>
  );
};

export default GeneralInfo;
