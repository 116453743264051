import { get } from '../utils/api';
import { getUrlParam } from '../utils';
import { UNITSERVICE_RESULT_LIMIT } from '../utils/constants';

export const getAvailableUnits = ({
  organizationId,
  propertyId,
  desiredBeds,
  desiredMoveInStartDate,
  desiredMoveInEndDate,
  flags,
}) => {
  const availabilityEmbedTheLimitDoesNotExistFlag =
    flags?.availabilityEmbedTheLimitDoesNotExist;
  const limit = availabilityEmbedTheLimitDoesNotExistFlag
    ? getUrlParam('limit')
    : getUrlParam('limit') || UNITSERVICE_RESULT_LIMIT;

  const options = {
    params: {
      desiredBeds,
      desiredMoveInStartDate,
      desiredMoveInEndDate,
      limit,
      sorted: true,
    },
  };

  return get(`/public/${organizationId}/${propertyId}/availableUnits`, options);
};
