import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { pathOr } from 'ramda';

import {
  UNITLIST_PERFECT_HOME_MAX,
  UNITLIST_INITIAL_SHOWING,
  UNITLIST_LOAD_MORE_STEP,
  UNITLIST_NO_PERFECT_MSG,
  UNITLIST_NO_RESULTS_MSG,
} from '../../utils/constants';

import H2 from '../atoms/H2';
import Button from '../atoms/Button';
import Spinner from '../atoms/Spinner';
import AvailableUnit from './AvailableUnit';

const StyledMessage = styled.p`
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  margin: 0;
  padding: ${(p) => p.theme.sizes.padding};
  text-align: center;
`;

const StyledLoadMoreWrapper = styled.div`
  padding: ${(p) => p.theme.sizes.padding};
`;

const LoadMoreButton = styled(Button)`
  display: block;
  margin: 0 auto;
  width: ${(p) => p.theme.sizes.buttonWidth};

  @media screen and ${(p) => p.theme.screens.mobile} {
    width: 100%;
  }
`;

const StyledSpinnerWrapper = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  text-align: center;
`;

const AvailableUnitList = ({
  applyNowEnabled,
  units,
  handleModal,
  isLoaded,
  isLoading,
}) => {
  const [openUnit, setOpenUnit] = useState('');
  const [showing, setShowing] = useState(UNITLIST_INITIAL_SHOWING);

  useEffect(() => {
    setShowing(UNITLIST_INITIAL_SHOWING);
    setOpenUnit(units.length > 0 ? units[0].unitId : '');
  }, [units]);

  const handleToggle = (id) => {
    if (openUnit === id) {
      return setOpenUnit('');
    }

    return setOpenUnit(id);
  };

  const handleLoadMore = () => {
    let newShowing = showing + UNITLIST_LOAD_MORE_STEP;
    if (newShowing > units.length) {
      newShowing = units.length;
    }

    setShowing(newShowing);
  };

  const renderAvailableUnits = () => {
    const list = [];
    let perfectCount = 0;
    let otherCount = 0;

    /**
     * Cannot show more than what's available.
     */
    let showingUnits = showing;
    if (showingUnits > units.length) {
      showingUnits = units.length;
    }

    if (units.length > 0) {
      for (let i = 0; i < showingUnits; i++) {
        const unit = units[i];
        const isPerfect = pathOr(false, ['sortPerfect'], unit);
        const availableUnitComp = (
          <AvailableUnit
            key={unit.unitId}
            applyNowEnabled={applyNowEnabled}
            unit={unit}
            isOpen={openUnit === unit.unitId}
            handleToggle={() => {
              handleToggle(unit.unitId);
            }}
            handleModal={() => {
              handleModal(unit.floorPlanImageName);
            }}
          />
        );

        // Append "Perfect Home" Header first
        if (perfectCount === 0 && otherCount === 0) {
          list.push(
            <H2 key='perfect-header' withBorder>
              Your Perfect Home!
            </H2>,
          );
        }

        // If first Unit is not Perfect, assume none are...
        if (i === 0 && isPerfect === false) {
          list.push(
            <StyledMessage key='no-perfect-units'>
              {UNITLIST_NO_PERFECT_MSG}
            </StyledMessage>,
          );
        }

        // If "Perfect Home" Unit...
        // Append "Perfect Home" Unit until PERFECT_HOME_MAX
        if (isPerfect === true && perfectCount < UNITLIST_PERFECT_HOME_MAX) {
          list.push(availableUnitComp);
          perfectCount += 1;
          continue;
        }

        // Append "Additional Options" Header
        if (otherCount === 0) {
          list.push(
            <H2 key='other-header' withBorder>
              Additional Options
            </H2>,
          );
        }

        // Append "Additional Option" Unit
        list.push(availableUnitComp);
        otherCount += 1;
      }
    }

    return list;
  };

  const renderLoadMore = () => {
    if (units.length > showing) {
      return (
        <StyledLoadMoreWrapper>
          <LoadMoreButton
            btnStyle='outline'
            type='button'
            onClick={() => {
              handleLoadMore();
            }}
          >
            Load More
          </LoadMoreButton>
        </StyledLoadMoreWrapper>
      );
    }

    return null;
  };

  const renderListContent = () => {
    if (isLoading === true) {
      return (
        <StyledSpinnerWrapper>
          <Spinner size='50px' />
        </StyledSpinnerWrapper>
      );
    }

    if (units.length > 0 && isLoaded === true) {
      return (
        <>
          {renderAvailableUnits()}
          {renderLoadMore()}
        </>
      );
    }

    if (units.length === 0 && isLoaded === true) {
      return <StyledMessage>{UNITLIST_NO_RESULTS_MSG}</StyledMessage>;
    }
  };

  return <div>{renderListContent()}</div>;
};

export default AvailableUnitList;
