import React from 'react';
import styled from 'styled-components';

import { formatNumber } from '../../../utils';

const StyledHeader = styled.p`
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 5px 0;
`;

const StyledAmenities = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${(p) => p.theme.sizes.font};
  line-height: 24px;
  margin: 0;
  padding: 0 0 0 15px;

  & > li {
    flex-basis: 25%;
  }

  @media screen and ${(p) => p.theme.screens.mobile} {
    & > li {
      flex-basis: 100%;
    }
  }
`;

const DetailInfo = ({
  unitId,
  unitNumber,
  unitAmenities,
  floorPlanSquareFeet,
}) => {
  const renderAmenities = () => {
    return unitAmenities.map((amenity, idx) => (
      <li key={`${unitId}-amenity-${idx}`}>{amenity}</li>
    ));
  };

  return (
    <div>
      <StyledHeader>Unit Details</StyledHeader>
      <StyledAmenities>
        <li>Size: {formatNumber(floorPlanSquareFeet)} sq. ft.</li>
        {renderAmenities()}
      </StyledAmenities>
    </div>
  );
};

export default DetailInfo;
