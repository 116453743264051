import React from 'react';

const NotFound = () => (
  <span>
    <strong>[Error]:&nbsp;</strong>
    Route Not Found.
  </span>
);

export default NotFound;
