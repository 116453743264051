import React from 'react';
import styled from 'styled-components';

const StyledBedBath = styled.div`
  align-items: center;
  color: ${(p) => p.theme.colors.detail};
  display: flex;
  flex-basis: 50%;
  font-size: ${(p) => p.theme.sizes.font};
  line-height: 25px;

  i {
    font-size: 23px;
    margin-right: 10px;
  }
`;

const BedBath = (props) => {
  const { txtStyle, count } = props;

  switch (txtStyle) {
    case 'baths':
      return (
        <StyledBedBath {...props}>
          <i className='et-tub' />
          <div>
            {count} {count === 1 ? 'Bath' : 'Baths'}
          </div>
        </StyledBedBath>
      );
      break;
    case 'beds':
    default:
      return (
        <StyledBedBath {...props}>
          <i className='et-bed' />
          <div>
            {count} {count === 1 ? 'Bedroom' : 'Bedrooms'}
          </div>
        </StyledBedBath>
      );
      break;
  }
};

export default BedBath;
