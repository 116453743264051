import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  border-top: ${(p) =>
    p.withBorder ? `1px solid ${p.theme.colors.border}` : 'none'};
  border-bottom: ${(p) =>
    p.withBorder ? `1px solid ${p.theme.colors.border}` : 'none'};
  color: ${(p) => p.theme.colors.header};
  font-family: 'Raleway', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: -0.3px;
  line-height: 75px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;

const H1 = (props) => {
  return <StyledH1 {...props}>{props.children}</StyledH1>;
};

export default H1;
