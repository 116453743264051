import React, { useState, useMemo, useEffect } from 'react';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { pathOr, isNil } from 'ramda';
import styled, { ThemeProvider } from 'styled-components';

import { getAvailableUnits } from '../services/availableUnitService';

import H1 from '../components/atoms/H1';
import FloorPlanModal from '../components/organisms/FloorPlanModal';
import AvailableUnitForm from '../components/organisms/AvailableUnitForm';
import AvailableUnitList from '../components/organisms/AvailableUnitList';

import theme from '../themes/UnitAvailabilityTheme';

import {
  UNITFORM_30_DAYS,
  UNITFORM_60_DAYS,
  UNITFORM_90_DAYS,
  UNITFORM_ALL_DAYS,
} from '../utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Button from '../components/atoms/Button';
import { buildIntentURL } from '../utils';

const StyledFrame = styled.div`
  background: ${(p) => p.theme.colors.background};
  border-left: 1px solid ${(p) => p.theme.colors.border};
  border-right: 1px solid ${(p) => p.theme.colors.border};
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  color: ${(p) => p.theme.colors.body};
  font-size: ${(p) => p.theme.sizes.font};
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`;

const StyledPinned = styled.div`
  background: ${(p) => p.theme.colors.background};
`;

const StyledContent = styled.div`
  overflow: auto;
`;

const StyledPinnedFooter = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.border};
  width: 100%;
  background: ${(p) => p.theme.colors.background};
`;

const FooterContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const FooterItem = styled.div`
  display: inline-block;
  color: ${(p) => p.theme.colors.footer};
  font-family: 'Mulish', 'Raleway', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  padding: 0 12px;
`;

const ApplyOnlineButton = styled(Button)`
  min-width: 170px;
  border: 2px solid ${(p) => p.theme.colors.footerLink};
  font-size: 16px;
  font-weight: 700;
`;

const TellUsLink = styled.a`
  color: ${(p) => p.theme.colors.footerLink};
  font-weight: 700;
`;

const UnitAvailability = ({ match, propertyDetails }) => {
  const flags = useFlags();
  const isAssignUnitsAtApplicationEnabled = flags?.assigningUnitsAtApplication;
  const propertySettingApplyWithoutAUnit =
    flags?.propertySettingApplyWithoutAUnit;
  const {
    organizationId,
    propertyId,
    configs: {
      applyWithoutUnit = false,
      isUnitSelectionEnabled = false,
      floorPlans = [],
    },
  } = propertyDetails ?? { configs: {} };

  /**
   * Instead of using a `useState(...)` for each value, I opted for a "Classic" State approach of a single `state` Object.
   *
   * There's two reasons for this:
   *   * Cleanliness - Managing multiple `useState(...)` calls can be troubling when you are dealing with +3 properties.
   *   * Performance - React has trouble batching multiple `useState(...)` calls into a single render.
   *       In this case, using `useState(...)` to set both `isLoading` and `isLoaded` could result in _two_ renders.
   *       While using `useState(...)` to update a single `state` Object guarantees a _single_ render.
   *       https://github.com/facebook/react/issues/14259
   */
  const [state, setState] = useState({
    applyNowEnabled: false,
    units: [],
    modal: '',
    isLoaded: false,
    isLoading: false,
  });

  const determineMoveInDates = (range) => {
    let desiredMoveInStartDate = null;
    let desiredMoveInEndDate = null;

    const today = moment();

    switch (range) {
      case UNITFORM_30_DAYS:
        desiredMoveInStartDate = today.format('MM/DD/YYYY');
        desiredMoveInEndDate = today.add(30, 'days').format('MM/DD/YYYY');
        break;
      case UNITFORM_60_DAYS:
        desiredMoveInStartDate = today.add(31, 'days').format('MM/DD/YYYY');
        desiredMoveInEndDate = today.add(60, 'days').format('MM/DD/YYYY');
        break;
      case UNITFORM_90_DAYS:
        desiredMoveInStartDate = today.add(61, 'day').format('MM/DD/YYYY');
        desiredMoveInEndDate = today.add(90, 'days').format('MM/DD/YYYY');
        break;
      default:
      case UNITFORM_ALL_DAYS:
        break;
    }

    return { desiredMoveInStartDate, desiredMoveInEndDate };
  };

  const handleSubmit = (values) => {
    const organizationId = pathOr(null, ['params', 'organizationId'], match);
    const propertyId = pathOr(null, ['params', 'propertyId'], match);

    if (isNil(organizationId) || isNil(propertyId)) {
      console.log(
        '[Error] Organization ID and Property ID are required.  Check URL params.',
      );
      return false;
    }

    setState({
      ...state,
      units: [],
      isLoaded: false,
      isLoading: true,
    });

    /**
     * Determine Move-In Dates from Move-In Date Range
     */
    const { desiredMoveInRange } = values;
    const {
      desiredMoveInStartDate,
      desiredMoveInEndDate,
    } = determineMoveInDates(desiredMoveInRange);

    const requestParams = {
      ...values,
      desiredMoveInStartDate,
      desiredMoveInEndDate,
      organizationId,
      propertyId,
    };

    getAvailableUnits({ ...requestParams, flags })
      .then((response) => {
        const applyNowEnabled = Boolean(
          isAssignUnitsAtApplicationEnabled && response?.meta?.applyNowEnabled,
        );

        setState({
          ...state,
          applyNowEnabled,
          units: response.data,
          isLoaded: true,
          isLoading: false,
        });
        return true;
      })
      .catch((error) => {
        console.log(
          `[Error] Encountered error getting Available Units:`,
          error,
        );
        setState({
          ...state,
          units: response.data,
          isLoaded: true,
          isLoading: false,
        });
        return true;
      });
  };

  const handleModal = (src) => {
    if (src) {
      return setState({
        ...state,
        modal: src,
      });
    }

    return setState({
      ...state,
      modal: src,
    });
  };

  const portalUrl = useMemo(
    () =>
      buildIntentURL({
        url: `${process.env.REACT_APP_PORTAL_URL}/${organizationId}/${propertyId}`,
        type: 'tellUs',
      }),
    [organizationId, propertyId],
  );

  const onApplyClick = () => {
    window.open(portalUrl, '_blank');
  };

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, [window.location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <StyledFrame>
        <FloorPlanModal target={state.modal} handleModal={handleModal} />
        <StyledPinned>
          <H1 withBorder>Find Your Next Home</H1>
          <AvailableUnitForm
            handleSubmit={handleSubmit}
            floorPlans={floorPlans}
          />
        </StyledPinned>
        <StyledContent isUnitSelectionEnabled={isUnitSelectionEnabled}>
          <AvailableUnitList
            applyNowEnabled={state.applyNowEnabled}
            units={state.units}
            isLoaded={state.isLoaded}
            isLoading={state.isLoading}
            handleModal={handleModal}
          />
        </StyledContent>
        {propertySettingApplyWithoutAUnit && !isUnitSelectionEnabled && (
          <StyledPinnedFooter>
            <FooterContainer>
              <FooterItem key='applyOnline'>
                <ApplyOnlineButton btnStyle='primary' onClick={onApplyClick}>
                  Apply Online
                </ApplyOnlineButton>
              </FooterItem>
            </FooterContainer>
          </StyledPinnedFooter>
        )}
        {propertySettingApplyWithoutAUnit &&
          isUnitSelectionEnabled &&
          applyWithoutUnit &&
          state.isLoaded && (
            <StyledPinnedFooter>
              <FooterContainer>
                <FooterItem key='message'>Don't see what you like?</FooterItem>
                <FooterItem key='portalLink'>
                  <TellUsLink href={portalUrl} target='_blank'>
                    Apply without a unit selection!
                  </TellUsLink>
                </FooterItem>
              </FooterContainer>
            </StyledPinnedFooter>
          )}
      </StyledFrame>
    </ThemeProvider>
  );
};

export default UnitAvailability;
