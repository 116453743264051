import React from 'react';
import styled from 'styled-components';

const StyledPrimaryButton = styled.button`
  background: ${(p) => p.theme.colors.buttonPrimary};
  border: none;
  border-radius: ${(p) => p.theme.sizes.radius};
  color: ${(p) => p.theme.colors.buttonPrimaryFont};
  cursor: pointer;
  font-size: ${(p) => p.theme.sizes.font};
  font-weight: bold;
  letter-spacing: -0.5;
  line-height: ${(p) => p.theme.sizes.formHeight};
  opacity: ${(p) => p.theme.colors.opacitySteps[0]};
  padding: 0;
  text-align: center;
  width: 100%;

  &:active {
    opacity: ${(p) => p.theme.colors.opacitySteps[2]};
  }
`;

const StyledSecondaryButton = styled(StyledPrimaryButton)`
  background: ${(p) => p.theme.colors.buttonSecondary};
  color: ${(p) => p.theme.colors.buttonSecondaryFont};
`;

const StyledOutlineButton = styled(StyledPrimaryButton)`
  background: ${(p) => p.theme.colors.background};
  box-shadow: 0 0 0 2px ${(p) => p.theme.colors.buttonPrimary} inset;
  color: ${(p) => p.theme.colors.buttonPrimary};
`;

const Button = (props) => {
  switch (props.btnStyle) {
    case 'secondary':
      return (
        <StyledSecondaryButton {...props}>
          {props.children}
        </StyledSecondaryButton>
      );
      break;
    case 'outline':
      return (
        <StyledOutlineButton {...props}>{props.children}</StyledOutlineButton>
      );
      break;
    default:
    case 'primary':
      return (
        <StyledPrimaryButton {...props}>{props.children}</StyledPrimaryButton>
      );
      break;
  }
};

export default Button;
