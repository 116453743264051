import React from 'react';
import styled from 'styled-components';

const StyledIconButton = styled.button`
  background: rgba(221, 221, 221, 0.867);
  border-radius: ${(p) => p.theme.sizes.radius};
  padding: 5px 6px;
  position: absolute;
  border: none;
  bottom: 0;
  left: 0;
  cursor: pointer;
`;

const UnitNumber = styled.p`
  flex: 0 0 100%;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  @media screen and ${(p) => p.theme.screens.mobile} {
    margin-bottom: 8px;
    display: none;
  }
`;

const Image = styled.img`
  height: 100px;
  cursor: pointer;
  border-radius: 4px;
`;

const Container = styled.div`
  padding: 20px;
  position: relative;
  @media screen and ${(p) => p.theme.screens.mobile} {
    padding: 0;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 4px;
  max-width: fit-content;
  height: 100px;
`;
const Floorplan = (props) => {
  return (
    <Container>
      <UnitNumber>Apartment #{props.unitNumber}</UnitNumber>
      <ImageContainer>
        <Image src={props.floorPlanImageName} onClick={props.handleModal} />
        <StyledIconButton onClick={props.handleModal}>
          <i className='et-search' />
        </StyledIconButton>
      </ImageContainer>
    </Container>
  );
};

export default Floorplan;
