import { get } from '../utils/api';
import { getUrlParam } from '../utils';

import { UNITSERVICE_RESULT_LIMIT } from '../utils/constants';

export const getPropertyConfigsAndDetails = ({
  organizationId,
  propertyId,
}) => {
  return get(
    `/public/${organizationId}/properties/${propertyId}/propertyConfig`,
  );
};
