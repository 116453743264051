import React from 'react';
import styled from 'styled-components';

const StyledH2 = styled.h2`
  border-bottom: ${(p) =>
    p.withBorder ? `1px solid ${p.theme.colors.border}` : 'none'};
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 50px;
  margin: 0;
  text-align: center;
`;

const H2 = (props) => {
  return <StyledH2 {...props}>{props.children}</StyledH2>;
};

export default H2;
