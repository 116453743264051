import React, { useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import Button from '../atoms/Button';
import { DATE_FORMAT_DB } from '../../utils/constants';

const ApplyButton = styled(Button)`
  display: block;
  margin: auto;
  width: ${(p) => p.theme.sizes.buttonWidth};
  height: 40px;
  max-width: 200px;
  flex: 0 0 25%;
  @media screen and ${(p) => p.theme.screens.mobile} {
    margin: 8px 0 0 0;
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
  }
`;
const ApplyNowButton = ({ urlState }) => {
  const { pathname } = window.location;

  const onClick = useCallback(() => {
    const match = matchPath(pathname, {
      path: '/unit-availability/:organizationId/:propertyId',
      exact: false,
      strict: false,
    });
    if (match?.params?.organizationId && match?.params?.propertyId) {
      const { organizationId, propertyId } = match.params;

      const unitData = {
        type: 'assignUnit',
        unitId: urlState?.unitId,
        unitMoveInDate: moment(urlState?.unitMoveInDate).format(DATE_FORMAT_DB),
        unitMoveInEndDate: moment(urlState?.unitMoveInEndDate).format(
          DATE_FORMAT_DB,
        ),
        floorPlanBeds: urlState?.floorPlanBeds,
      };

      const params = new URLSearchParams({
        state: JSON.stringify(unitData),
      });
      const url = new URL(
        `${process.env.REACT_APP_PORTAL_URL}/${organizationId}/${propertyId}/intent/?${params}`,
      );
      setTimeout(() => (window.location.href = url), 300);
    }
  }, [pathname]);

  return (
    <ApplyButton onClick={onClick} btnStyle='outline' type='button'>
      Apply Now
    </ApplyButton>
  );
};

export default ApplyNowButton;
