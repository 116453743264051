import React from 'react';

import {
  UNITFORM_30_DAYS,
  UNITFORM_60_DAYS,
  UNITFORM_90_DAYS,
  UNITFORM_ALL_DAYS,
} from '../../../utils/constants';

import Label from '../../atoms/Label';
import Select from '../../atoms/Select';

const MoveInRangeField = (props) => {
  return (
    <div {...props}>
      <Label htmlFor='desiredMoveInRange'>Move-in date</Label>
      <Select
        id='desiredMoveInRange'
        name='desiredMoveInRange'
        options={[
          { text: 'Within 30 Days', value: UNITFORM_30_DAYS },
          { text: '31-60 Days From Now', value: UNITFORM_60_DAYS },
          { text: '61-90 Days From Now', value: UNITFORM_90_DAYS },
          { text: 'Show Me Everything', value: UNITFORM_ALL_DAYS },
        ]}
      />
    </div>
  );
};

export default MoveInRangeField;
